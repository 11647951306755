import React, { useState, useEffect } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import { postData } from '../communications/Backend';
import { trackEvent, trackException } from '../tools/GaTools';
import PopupCard from '../components/PopupCard'; // ���� PopupCard ���
import PopupTitle from '../components/PopupTitle';
import PopupInfo from '../components/PopupInfo';
import PopupContent from '../components/PopupContent';
import ButtonSingle from '../components/ButtonSingle';
import ButtonNormal from '../components/ButtonNormal';
import LoadingSpinner from '../components/LoadingSpinner';
import CountdownTimer from '../components/CountdownTimer';
import CloseIcon from '@mui/icons-material/Close'; // ����ر�ͼ��

export function MoreGems({ open, onClose, updateTicket }) {
    const [state, setState] = useState('loading'); // loading, success, error
    const [checkinSubmitState, setCheckinSubmitState] = useState('idle'); // idle, unavailable, loading, success, error
    const [conversionState, setConversionState] = useState('idle'); // idle, unavailable, loading, success, error
    const [ticketNumber, setTicketNumber] = useState(0);
    const [secondaryTicketNumber, setSecondaryTicketNumber] = useState(0);
    const [reward, setReward] = useState(0);
    const [consecutiveCheckinDays, setConsecutiveCheckinDays] = useState(0);
    const [secondaryTicketConversionRatio, setSecondaryTicketConversionRatio] = useState(0);
    const [secondaryConversionNumber, setSecondaryConversionNumber] = useState(0);
    const [isCheckinAvailable, setIsCheckinAvailable] = useState(false);
    const [checkinRewardList, setCheckinRewardList] = useState([]);

    // ------------api------------ //
    const handleCheckinFetchSuccess = (data) => {

        if (!data || Object.keys(data).length === 0) {
            handleCheckinFetchError('Data Empty');
            return;
        }

        setTicketNumber(data.ticketNumber);
        setSecondaryTicketNumber(data.secondaryTicketNumber);
        setSecondaryTicketConversionRatio(data.secondaryTicketConversionRatio);
        setCheckinRewardList(data.checkinRewardList);
        setConsecutiveCheckinDays(data.consecutiveCheckinDays);
        setIsCheckinAvailable(data.isCheckinAvailable);

        updateTicket(data.ticketNumber);

        setState('success');
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: 'CheckinInfo',
        });
    };

    const handleCheckinFetchError = (error) => {
        setState('error');
        trackException({
            description: `Checkin info Fetched Failed: ${error}`,
        });
    };

    const handleCheckinSubmitSuccess = (data) => {

        if (!data || Object.keys(data).length === 0) {
            handleCheckinSubmitError('Data Empty');
            return;
        }

        setTicketNumber(data.ticketNumber);
        setSecondaryTicketNumber(data.secondaryTicketNumber);
        setConsecutiveCheckinDays(data.consecutiveCheckinDays);
        setReward(data.reward);
        setIsCheckinAvailable(false);

        updateTicket(data.ticketNumber);

        setCheckinSubmitState('success');
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: 'CheckinSubmit',
        });
    };

    const handleCheckinSubmitError = (error) => {
        setCheckinSubmitState('error');
        trackException({
            description: `Checkin info Submit Failed: ${error}`,
        });
    };

    const handleSecondaryTicketConversionSuccess = (data) => {

        if (!data || Object.keys(data).length === 0) {
            handleSecondaryTicketConversionError('Data Empty');
            return;
        }

        setTicketNumber(data.ticketNumber);
        setSecondaryTicketNumber(data.secondaryTicketNumber);
        setSecondaryConversionNumber(data.conversion);

        updateTicket(data.ticketNumber);

        setConversionState('success');
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: 'SecondaryTicketConversion',
        });
    };

    const handleSecondaryTicketConversionError = (error) => {
        setConversionState('error');
        trackException({
            description: `Secondary Ticket Conversion Failed: ${error}`,
        });
    };

    const fetchCheckinInfo = async () => {
        try {
            await postData(
                'daily-checkin-info',
                null,
                handleCheckinFetchSuccess,
                handleCheckinFetchError
            );
        } catch (error) {
            handleCheckinFetchError(error)
        }
    };

    const submitCheckin = async () => {
        try {
            await postData(
                'daily-checkin-submit',
                null,
                handleCheckinSubmitSuccess,
                handleCheckinSubmitError
            );
        } catch (error) {
            handleCheckinSubmitError(error)
        }
    }

    const submitSecondaryTicketConversion = async () => {
        try {
            await postData(
                'secondary-currency-conversion',
                null,
                handleSecondaryTicketConversionSuccess,
                handleSecondaryTicketConversionError
            );
        } catch (error) {
            handleSecondaryTicketConversionError(error)
        }
    }
    // ----------api end---------- //

    // ------------action------------ //
    const fetchAndRefreshCheckinInfo = async () => {
        setState('loading')

        const timeoutId = setTimeout(() => {
            setState('error');
            trackException({
                description: `Checkin info Fetched Time out`,
            });
        }, 10000); // 10�볬ʱ

        try {
            await fetchCheckinInfo();
            clearTimeout(timeoutId);
        } catch (error) {
            clearTimeout(timeoutId);
            setState('error');
            trackException({
                description: `Checkin info Fetched Failed: ${error}`,
            });
        }

        return timeoutId;
    };

    const handleCheckinPress = async() => {
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: `Checkin - ${isCheckinAvailable}`,
        });

        if (isCheckinAvailable === true) {
            setCheckinSubmitState('loading');
            submitCheckin();
        } else {
            setCheckinSubmitState('unavailable');
        }
    }

    const handleConversionPress = async () => {
        const isConversionAvailable = secondaryTicketNumber >= secondaryTicketConversionRatio;

        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: `Conversion - ${isConversionAvailable}`,
        });

        if (isConversionAvailable === true) {
            setConversionState('loading');
            submitSecondaryTicketConversion();
        } else {
            setConversionState('unavailable');
        }
    }
    // ----------action end---------- //

    useEffect(() => {
        if (open) {
            const timeoutId = fetchAndRefreshCheckinInfo();
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open]);

    const DiamondIcon = () => {
        return (
            <img
                src="/icon-diamond.png"
                alt="icon"
                style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '4px',
                    verticalAlign: 'middle',
                    transform: 'translateY(-2px)',
                }}
            />
        );
    }
    const SecondaryDiamondIcon = () => {
        return (
            <img
                src="/icon-smallDiamond.png"
                alt="icon"
                style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '4px',
                    verticalAlign: 'middle',
                    transform: 'translateY(-2px)',
                }}
            />
        );
    }

    const IconTextComponent = ({ iconSrc, text }) => (
        <Box
            width='100px'
            sx={{
                color: '#FFFFFF',
                textAlign: 'left',
                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                mt: 1,
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    textTransform: 'none',
                    display: 'flex', // ʹ��flex����
                    alignItems: 'center', // ��ֱ���ж���
                }}
            >
                <img
                    src={iconSrc}
                    alt="icon"
                    style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                        transform: 'translateY(-2px)', // ����΢��
                    }}
                />
                {text}
            </Typography>
        </Box>
    );

    const ConversionRatio = ({ ratio, smallIconSrc, largeIconSrc }) => {
        return (
            <Typography
                sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    mt: 0.5,
                }}
            >
                ( {ratio}
                <img
                    src={smallIconSrc}
                    alt="small icon"
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        verticalAlign: 'middle',
                        transform: 'translateY(-2px)',
                    }}
                />
                = 1
                <img
                    src={largeIconSrc}
                    alt="large icon"
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '2px',
                        marginRight: '3px',
                        verticalAlign: 'middle',
                        transform: 'translateY(-2px)',
                    }}
                />
                )
            </Typography>
        );
    };

    const CheckinReward = ({ checkinReward }) => {
        let backgroundColor;
        let dayTextColor;
        let overlayText = null;

        let isCurrentDay = checkinReward.day === (isCheckinAvailable ? consecutiveCheckinDays + 1 : consecutiveCheckinDays)
        let isRewarded = checkinReward.day <= consecutiveCheckinDays

        if (isRewarded === true) {
            overlayText = 'Rewarded';
        }

        if (isCurrentDay === true) {
            backgroundColor = '#FFA500';
            dayTextColor = '#FF6F00';
        } else {
            backgroundColor = '#6ba3ff';
            dayTextColor = '#4a7bce';
        }

        return (
            <Box
                sx={{
                    background: backgroundColor,
                    position: 'relative',
                    borderRadius: '10px',
                    width: '100%',
                    overflow: 'hidden',
                    opacity: isRewarded === true ? 0.5 : 1, // �����û�
                }}
            >
                {overlayText && (
                    <Typography
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%) rotate(-15deg)', // ������תЧ��
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                            zIndex: 1, // ȷ���ı������ϲ�
                        }}
                    >
                        {overlayText}
                    </Typography>
                )}
                <Typography
                    variant="h6"
                    color="#F5F5F5"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                        backgroundColor: dayTextColor,
                    }}
                >
                    Day {checkinReward.day}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '24px',
                            textTransform: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="/icon-smallDiamond.png"
                            alt="icon"
                            style={{
                                width: '30px',
                                height: '30px',
                                marginRight: '10px',
                                verticalAlign: 'middle',
                                transform: 'translateY(-2px)',
                            }}
                        />
                        {checkinReward.reward}
                    </Typography>
                </Box>
            </Box>
        );
    }

    const renderCheckinIntroduction = () => {
        return (
            <Box
                width="100%"
                height = '70px'
                sx={{
                    color: '#FFFFFF',
                    display: 'flex', // ����Ϊflex����
                    alignItems: 'center', // ��ֱ���ж���
                    gap: 0, // �������֮��ļ��
                }}
            >
                <Box
                    width="60%"
                    height="90%"
                    sx={{
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                        display: 'flex', // ����Ϊflex����
                        marginTop:0,
                        marginLeft: 0, // �������
                        mt: 1,
                    }}
                >
                    <IconTextComponent
                        iconSrc="/icon-diamond.png"
                        text={ticketNumber}
                    />
                    <IconTextComponent
                        iconSrc="/icon-smallDiamond.png"
                        text={secondaryTicketNumber}
                    />

                </Box>
                <Box
                    width="40%"
                    height="50%"
                    sx={{
                        display: 'flex', // ����Ϊflex����
                        flexDirection: 'column', // ��ֱ����
                        alignItems: 'center', // ��ֱ���ж���
                        marginTop: 0,
                        marginLeft: 'auto',
                        marginRight: 0,
                    }}
                >
                    <Box
                        width="100%"
                        sx={{
                            '& .MuiTypography-root': {
                                fontSize: '16px', // ��Box������Typography�������С
                            },
                        }}
                    >
                        <ButtonNormal
                            buttonInfo='Redeem Gem'
                            onClick={handleConversionPress}
                            colors={{
                                baseColor: '#8E44AD',
                                gradientStart: '#9B59B6',
                                gradientEnd: '#8E44AD',
                            }}
                        />
                    </Box>
                    <ConversionRatio
                        ratio={secondaryTicketConversionRatio}
                        smallIconSrc="/icon-smallDiamond.png"
                        largeIconSrc="/icon-diamond.png"
                    />
                </Box>
            </Box>
        );
    }

    const renderCheckinBox = () => {
        return (
            <Box
                width='100%'
                sx={{
                    borderRadius: '10px',
                    color: '#FFFFFF',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                    background: '#2c4a7b',
                    mt: 2,
                }}
            >
                <Typography
                    color="#F5F5F5"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                        fontStyle: 'italic',
                        mt: 1,
                    }}
                >
                    Daily Rewards
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)', // 3 �У�ÿ�еȿ�
                        gap: '16px', // ��ť֮��ļ��
                        width: '100%',
                        padding: '16px', // ���Ҹ���16px���ڱ߾�
                        margin: '0 auto', // ������ʾ
                        boxSizing: 'border-box', // �����ڱ߾����ڵĿ��ȼ���
                        '@media (max-width: 300px)': {
                            gridTemplateColumns: 'repeat(2, 1fr)', // ��Ļ����С��440pxʱ2��
                        },
                    }}
                >
                    {checkinRewardList.map((checkinReward, index) => (
                        <CheckinReward
                            key={index}
                            checkinReward={checkinReward}
                        />
                    ))}
                </Box>

                <Box
                    width="100%"
                    sx={{
                        color: '#FFFFFF',
                        display: 'flex', // ����Ϊflex����
                        alignItems: 'center', // ��ֱ���ж���
                        justifyContent: 'center', // ˮƽ���ж���
                        mb:0,
                    }}
                >
                    <ButtonSingle onClick={handleCheckinPress} clickInfo='Check-in' />
                </Box>
                <Box
                    sx={{
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                        display: 'flex', // ����Ϊflex����
                        justifyContent: 'flex-end', // ʹ���ݿ��Ҷ���
                        marginLeft: 'auto',
                        marginRight: 1,
                    }}
                >
                    <CountdownTimer />
                </Box>
            </Box>
        );
    }

    const renderNetworkError = () => {
        const onPopupClose = () => {
            onClose();
        };
        return (
            <PopupCard
                open={true}
                onClose={onPopupClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onPopupClose} clickInfo="Close" />
            </PopupCard>
        );
    }

    const renderCheckinUnavailable = () => {
        const onPopupClose = () => {
            setCheckinSubmitState('idle');
        };
        return (
            <PopupCard
                open={true}
                onClose={onPopupClose}
            >
                <PopupTitle title="Tips" />
                <PopupContent>
                    <PopupInfo info={
                        `You've already completed your daily check-in.
                         Please come back tomorrow!` } />
                </PopupContent>
                <ButtonSingle onClick={onPopupClose} clickInfo="Close" />
            </PopupCard>
        );
    }

    const renderCheckinSuccess = () => {
        const onPopupClose = () => {
            setCheckinSubmitState('idle');
        };
        const info = (
            <>
                You have received
                {'\n'}
                <SecondaryDiamondIcon /> x{reward}
                {'\n'}
                Now you have
                {'\n'}
                <SecondaryDiamondIcon /> x{secondaryTicketNumber}
                {'\n'}
                {'\n'}
                Continuous check-ins grant more rewards.
                {'\n'}
                Any interruption will reset to Day 1.
            </>
        );
        return (
            <PopupCard
                open={true}
                onClose={onPopupClose}
            >
                <PopupTitle title="Congratulations" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={onPopupClose} clickInfo="Continue" />
            </PopupCard>
        );
    }

    const renderConversionUnavailable = () => {
        const onPopupClose = () => {
            setConversionState('idle');
        };
        const info = (
            <>
                You need <SecondaryDiamondIcon /> x{secondaryTicketConversionRatio}
                {'\n'}
                to redeem <DiamondIcon /> x1
                {'\n'}
                Now you have only <SecondaryDiamondIcon /> x{secondaryTicketNumber}
            </>
        );
        return (
            <PopupCard
                open={true}
                onClose={onPopupClose}
            >
                <PopupTitle title="Tips" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={onPopupClose} clickInfo="Close" />
            </PopupCard>
        );
    }

    const renderConversionSuccess = () => {
        const onPopupClose = () => {
            setConversionState('idle');
        };
        const info = (
            <>
                You have successfully redeemed
                {'\n'}
                <SecondaryDiamondIcon /> x{secondaryConversionNumber * secondaryTicketConversionRatio} to <DiamondIcon /> x{secondaryConversionNumber}
                {'\n'}
                Now you have
                {'\n'}
                <DiamondIcon /> x{ticketNumber} and <SecondaryDiamondIcon /> x{secondaryTicketNumber}
            </>
        );
        return (
            <PopupCard
                open={true}
                onClose={onPopupClose}
            >
                <PopupTitle title="Congratulations" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={onPopupClose} clickInfo="Continue" />
            </PopupCard>
        );
    }

    return (
        <>
            <PopupCard
                open={open}
                onClose={onClose}
                width="100%"
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: '#FFFFFF',
                    }}
                >
                    <CloseIcon sx={{
                        fontWeight: 'bold', // �Ӵ�
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' // ����Ӱ
                    }} />
                </IconButton>

                <PopupTitle title="More Gems" />
                <PopupContent>
                    {renderCheckinIntroduction()}
                    {renderCheckinBox()}
                </PopupContent>
            </PopupCard>
            {state === 'loading' && <LoadingSpinner />}
            {state === 'error' && renderNetworkError()}

            {checkinSubmitState === 'loading' && <LoadingSpinner />}
            {checkinSubmitState === 'unavailable' && renderCheckinUnavailable()}
            {checkinSubmitState === 'success' && renderCheckinSuccess()}
            {checkinSubmitState === 'error' && renderNetworkError()}

            {conversionState === 'loading' && <LoadingSpinner />}
            {conversionState === 'unavailable' && renderConversionUnavailable()}
            {conversionState === 'success' && renderConversionSuccess()}
            {conversionState === 'error' && renderNetworkError()}
        </>
    );
}
